/* eslint-disable array-callback-return */
import Layout from '@layout';
import Error from 'next/error';
import { StripHtmlTags } from '@helper_text';
import { features, modules, debuging } from '@config';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { getCookies } from '@helper_cookies';
import Loading from '@core_modules/product/pages/default/components/Loader';
import {
    getProductQuery,
    addWishlist as mutationAddWishlist,
    getRelatedProductsQuery,
    getUpsellProductsQuery,
} from '@core_modules/product/services/graphql';
import Header from '@core_modules/product/pages/default/components/header';
import generateSchemaOrg from '@core_modules/product/helpers/schema.org';

const ContentDetail = ({
    t, product,
    Content,
    isLogin,
    weltpixel_labels,
    loadingRelatedProducts,
    dataRelatedProducts,
    loadingUpsellProducts,
    dataUpsellProducts,
}) => {
    const item = product[0];
    const route = useRouter();
    const productCategories = item.categories;

    const reviewValue = parseInt(item.review.rating_summary, 0) / 20;
    const soldItem = item?.additional_data?.sold_qty;

    React.useEffect(() => {
        // let index = 0;
        let categoryProduct = '';
        let categoryOne = '';
        // eslint-disable-next-line no-unused-expressions
        item.categories.length > 0 && (
            categoryOne = item.categories[0].name,
            item.categories.map(({ name }, indx) => {
                if (indx > 0) categoryProduct += `/${name}`;
                else categoryProduct += name;
            })
        );
        const tagManagerArgs = {
            dataLayer: {
                pageName: item.name,
                pageType: 'product',
                ecommerce: {
                    detail: {
                        product: [{
                            name: item.name,
                            id: item.sku,
                            price: item.price_range.minimum_price.regular_price.value || 0,
                            category: categoryProduct,
                            dimensions4: item.stock_status,
                            dimensions5: reviewValue,
                            dimensions6: item.review.reviews_count,
                            dimensions7: item.sale === 0 ? 'NO' : 'YES',
                            dimensions8: soldItem,
                        }],
                    },
                    currencyCode: item.price_range.minimum_price.regular_price.currency || 'USD',
                    impressions: [
                        // ...item.related_products.map((val) => {
                        //     index += 1;
                        //     return ({
                        //         name: val.name,
                        //         id: val.sku,
                        //         category: categoryProduct,
                        //         price: val.price_range.minimum_price.regular_price.value,
                        //         list: `Related Products From ${item.name}`,
                        //         position: index,
                        //     });
                        // }),
                        // ...item.upsell_products.map((val) => {
                        //     index += 1;
                        //     return ({
                        //         name: val.name,
                        //         id: val.sku,
                        //         category: categoryProduct,
                        //         price: val.price_range.minimum_price.regular_price.value,
                        //         list: `Related Products From ${item.name}`,
                        //         position: index,
                        //     });
                        // }),
                    ],
                },
                event: 'impression',
                eventCategory: 'Ecommerce',
                eventAction: 'Impression',
                eventLabel: item.name,
            },
        };

        const tagManagerArgsGA4 = {
            dataLayer: {
                pageName: item.name,
                pageType: 'product',
                ecommerce: {
                    items: [
                        {
                            item_name: item.name,
                            item_id: item.sku,
                            price: item.price_range.minimum_price.regular_price.value || 0,
                            item_category: categoryOne,
                            currency: item.price_range.minimum_price.regular_price.currency || 'USD',
                            item_stock_status: item.stock_status,
                            item_reviews_score: parseInt(item.review.rating_summary, 0) / 20,
                            item_reviews_count: item.review.reviews_count,
                        },
                    ],
                },
                event: 'view_item',
            },
        };
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer(tagManagerArgs);
        // Clear the previous ecommerce object.
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        TagManager.dataLayer(tagManagerArgsGA4);
    }, []);

    // const client = useApolloClient();

    // if (typeof window !== 'undefined') console.log(item);

    const bannerData = [];
    if (item.media_gallery.length > 0) {
        // eslint-disable-next-line array-callback-return
        item.media_gallery.map((media) => {
            bannerData.push({
                link: '#',
                imageUrl: media.url,
            });
        });
    } else {
        bannerData.push({
            link: '#',
            imageUrl: item.image.url,
        });
    }

    const [openOption, setOpenOption] = React.useState(false);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [openShare, setOpenShare] = React.useState(false);
    const [openImageDetail, setOpenImageDetail] = React.useState(false);
    const [banner, setBanner] = React.useState(bannerData);
    const [price, setPrice] = React.useState({
        priceRange: item.price_range,
        priceTiers: item.price_tiers,
        // eslint-disable-next-line no-underscore-dangle
        productType: item.__typename,
        specialFromDate: item.special_from_date,
        specialToDate: item.special_to_date,
    });
    const [additionalPrice, setAdditionalPrice] = React.useState(0);
    const [stockStatus, setStockStatus] = React.useState(item.stock_status);
    const [wishlist, setWishlist] = React.useState(false);
    const [selectedColorSwatch, setSelectedColorSwatch] = React.useState({});

    // Customizable Options
    const [customizableOptions, setCustomizableOptions] = React.useState([]);
    const [errorCustomizableOptions, setErrorCustomizableOptions] = React.useState([]);

    const [addWishlist] = mutationAddWishlist();

    const handleWishlist = () => {
        if (isLogin && isLogin === 1) {
            TagManager.dataLayer({
                dataLayer: {
                    event: 'addToWishlist',
                    eventLabel: item.name,
                    label: item.name,
                    ecommerce: {
                        currencyCode: item.price_range.minimum_price.regular_price.currency || 'USD',
                        add: {
                            products: [{
                                name: item.name,
                                id: item.sku,
                                price: item.price_range.minimum_price.regular_price.value || 0,
                                category: item.categories.length > 0 ? item.categories[0].name : '',
                                list: item.categories.length > 0 ? item.categories[0].name : '',
                                dimensions4: item.stock_status,
                            }],
                        },
                    },
                },
            });
            addWishlist({
                variables: {
                    productId: item.id,
                },
            }).then(async () => {
                // Data Layer GA4
                TagManager.dataLayer({
                    dataLayer: {
                        ecommerce: {
                            action: {
                                items: [
                                    {
                                        currency: item.price_range.minimum_price.regular_price.currency,
                                        item_name: item.name,
                                        item_id: item.sku,
                                        price: item.price_range.minimum_price.regular_price.value || 0,
                                        item_category: item.categories.length > 0 ? item.categories[0].name : '',
                                        item_stock_status: item.stock_status,
                                    },
                                ],
                            },
                        },
                        event: 'add_to_wishlist',
                    },
                });
                await setWishlist(!wishlist);
                await window.toastMessage({ open: true, variant: 'success', text: t('common:message:feedSuccess') });
                route.push('/wishlist');
            }).catch((e) => {
                window.toastMessage({
                    open: true,
                    variant: 'error',
                    text: debuging.originalError ? e.message.split(':')[1] : t('common:message:feedFailed'),
                });
            });
        } else {
            window.toastMessage({
                open: true,
                variant: 'warning',
                text: t('catalog:wishlist:addWithoutLogin'),
            });
        }
    };

    let overviewData = [];
    let expandData = [];
    let technologyAttr = [];

    if (item.more_info && item.more_info.length > 0) {
        overviewData = [
            ...overviewData,
            {
                title: 'Details',
                type: 'array',
                content: item.more_info,
                sku: item.sku,
            },
        ];
    }

    if (item.additional_info && item.additional_info.info_penting) {
        overviewData = [
            ...overviewData,
            {
                title: 'Info Penting',
                type: 'html',
                content: item.additional_info.info_penting,
            },
        ];
    }

    if (item.product_detail) {
        overviewData = [
            ...overviewData,
            {
                title: 'Product Detail',
                type: 'html',
                content: item.product_detail,
            },
        ];
    }

    if (item.garment_care) {
        overviewData = [
            ...overviewData,
            {
                title: 'Garment Care Instruction',
                type: 'html',
                content: item.garment_care,
            },
        ];
    }

    if (item.description.html) {
        expandData = [
            ...expandData,
            {
                title: t('product:ProductDetails'),
                type: 'html',
                tabContent: 'description',
                content: item.description.html,
            },
        ];
    }

    if (item.catalog_technology.length) {
        technologyAttr = [
            ...technologyAttr,
            {
                title: t('product:ProductDetailsTechnology'),
                type: 'technology',
                content: item.catalog_technology,
            },
        ];
    }

    if (item.additional_info && item.additional_info.info_pengiriman) {
        expandData = [
            ...expandData,
            {
                title: 'Info Pengiriman',
                type: 'html',
                content: item.additional_info.info_pengiriman,
            },
        ];
    }

    if (item.additional_info && item.additional_info.kebijakan_pengembalian) {
        expandData = [
            ...expandData,
            {
                title: 'Kebijakan Pengembalian',
                type: 'html',
                content: item.additional_info.kebijakan_pengembalian,
            },
        ];
    }

    // const relateData = item.related_products.map((val) => ({
    //     ...val,
    //     name: val.name,
    //     link: val.url_key,
    //     imageSrc: val.small_image.url,
    //     price: val.price_range.minimum_price.regular_price.value,
    // }));
    let breadcrumbsData = [];
    let lastBreadcrumbsData = {};
    if (typeof window !== 'undefined') {
        const lastCategory = getCookies('lastCategory');
        const cat = item.categories.filter(({ url_path }) => url_path === lastCategory);
        if (cat.length > 0) {
            if (cat[0].breadcrumbs && cat[0].breadcrumbs.length > 0) {
                breadcrumbsData = cat[0].breadcrumbs.map((bc) => ({
                    label: bc.category_name,
                    link: `/${bc.category_url_path}`,
                    active: false,
                    id: bc.category_id,
                }));
            }
            breadcrumbsData.push({
                label: cat[0].name,
                link: `/${cat[0].url_path}`,
                active: false,
                id: cat[0].id,
            });
            lastBreadcrumbsData = {
                label: cat[0].name,
                link: `/${cat[0].url_path}`,
                id: cat[0].id,
            };
        }

        breadcrumbsData.push({
            label: item.name,
            link: '#',
            active: true,
        });
    }

    const categoryNow = productCategories.find((obj) => obj.id === lastBreadcrumbsData.id);

    const handleOption = () => {
        const { productAvailableToCart } = features;
        // eslint-disable-next-line no-underscore-dangle
        if (productAvailableToCart[item.__typename]) {
            setOpenOption(true);
        } else {
            window.toastMessage({
                variant: 'warning',
                text: t('product:productNotAvailable'),
                open: true,
            });
        }
    };

    const handleOpenImageDetail = () => {
        setOpenImageDetail(!openImageDetail);
    };

    const checkCustomizableOptionsValue = async () => {
        if (item.options && item.options.length > 0) {
            const requiredOptions = item.options.filter((op) => op.required);
            if (requiredOptions.length > 0) {
                if (customizableOptions.length > 0) {
                    let countError = 0;
                    const optionsError = [];
                    for (let idx = 0; idx < requiredOptions.length; idx += 1) {
                        const op = requiredOptions[idx];
                        const findValue = customizableOptions.find((val) => val.option_id === op.option_id);
                        if (!findValue) {
                            optionsError.push(op);
                            countError += 1;
                        }
                    }
                    if (countError > 0) {
                        await setErrorCustomizableOptions(optionsError);
                        return false;
                    }
                    return true;
                }
                await setErrorCustomizableOptions(requiredOptions);

                return false;
            }
            return true;
        }
        return true;
    };

    React.useEffect(() => {
        if (errorCustomizableOptions && errorCustomizableOptions.length > 0) {
            // eslint-disable-next-line consistent-return
            const errorCustomizable = errorCustomizableOptions.filter((err) => {
                const findValue = customizableOptions.find((op) => op.option_id === err.option_id);
                return !findValue;
            });
            setErrorCustomizableOptions(errorCustomizable);
        }
    }, [customizableOptions]);

    const enablePopupImage = true;

    let configurablePrice = {};

    // eslint-disable-next-line no-underscore-dangle
    if (item.__typename === 'ConfigurableProduct') {
        configurablePrice = {
            priceRange: item.price_range,
            maxDiscount: item.max_discount,
        };
    }

    const salableQtyChildProducts = item?.salable_qty?.child_products?.reduce((accumulator, currentValue) => {
        const attributeCode = {};
        // eslint-disable-next-line no-unused-expressions
        currentValue.option?.forEach((eachOption) => {
            attributeCode[eachOption.attribute_code] = eachOption.value;
        });
        attributeCode.qty = currentValue.qty;
        accumulator.push(attributeCode);
        return accumulator;
    }, []);

    return (
        <Content
            data={product[0]}
            loadingRelatedProducts={loadingRelatedProducts}
            dataRelatedProducts={dataRelatedProducts}
            loadingUpsellProducts={loadingUpsellProducts}
            dataUpsellProducts={dataUpsellProducts}
            t={t}
            openOption={openOption}
            handleOption={handleOption}
            setOpenOption={setOpenOption}
            setBanner={setBanner}
            setPrice={setPrice}
            openShare={openShare}
            setOpenShare={setOpenShare}
            route={route}
            banner={banner}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            breadcrumbsData={breadcrumbsData}
            price={price}
            handleWishlist={handleWishlist}
            reviewValue={reviewValue}
            wishlist={wishlist}
            overviewData={overviewData}
            expandData={expandData}
            technologyAttr={technologyAttr}
            // anotherProduct={anotherProduct}
            // relateData={relateData}
            features={features}
            config={modules.catalog.pdp}
            openImageDetail={openImageDetail}
            handleOpenImageDetail={handleOpenImageDetail}
            stockStatus={stockStatus}
            setStockStatus={setStockStatus}
            customizableOptions={customizableOptions}
            setCustomizableOptions={setCustomizableOptions}
            errorCustomizableOptions={errorCustomizableOptions}
            checkCustomizableOptionsValue={checkCustomizableOptionsValue}
            additionalPrice={additionalPrice}
            setAdditionalPrice={setAdditionalPrice}
            enablePopupImage={enablePopupImage}
            soldItem={soldItem}
            categoryNow={categoryNow}
            weltpixel_labels={weltpixel_labels}
            selectedColorSwatch={selectedColorSwatch}
            setSelectedColorSwatch={setSelectedColorSwatch}
            configurablePrice={configurablePrice}
            salableQtyChildProducts={salableQtyChildProducts}
        />
    );
};

const PageDetail = (props) => {
    let product = {};
    let weltpixel_labels = [];
    const {
        slug, Content, t, isLogin, pageConfig, CustomHeader,
    } = props;

    const {
        loading, data, error,
    } = getProductQuery(slug[0]);

    const {
        loading: loadingRelatedProducts,
        data: dataRelatedProducts,
    } = getRelatedProductsQuery(slug[0]);

    const {
        loading: loadingUpsellProducts,
        data: dataUpsellProducts,
    } = getUpsellProductsQuery(slug[0]);

    if (error || loading || !data) {
        return (
            <Layout pageConfig={{}} CustomHeader={CustomHeader ? <CustomHeader /> : <Header />} {...props}>
                <Loading />
            </Layout>
        );
    }

    if (data) {
        product = data.customProductSearch;
        if (product.length === 0) return <Error statusCode={404} />;
    }

    const schemaOrg = generateSchemaOrg(product[0]);

    if (data && data.customProductSearch && data.customProductSearch.length > 0 && data.customProductSearch[0].weltpixel_labels) {
        weltpixel_labels = data.customProductSearch[0].weltpixel_labels;
    }

    const productName = product.length > 0 ? product[0].name : '';
    const brandName = product[0].more_info.find((item) => item.label === 'Brand');

    const metaTitle = product[0].meta_title && product[0].meta_title.trim() !== ''
        ? product[0].meta_title
        : `Jual ${productName} | ${brandName.value}`;

    /* eslint-disable-next-line max-len */
    const metaDesciptionmessage = `Beli ${productName} dengan harga hanya ${product[0].price_range.minimum_price.final_price.value} di 9TO9 Indonesia | Gratis Ongkir ✔️️️ Original ✔️️️. Dapatkan diskon tambahan 20% untuk pelanggan baru, Pastikan untuk memasukkan kode NEW20 di halaman checkout sebelum menyelesaikan transaksi.`;

    const metaDesciption = product[0].meta_description && product[0].meta_description.trim() !== ''
        ? product[0].meta_description
        : metaDesciptionmessage;

    const config = {
        title: product.length > 0 ? product[0].meta_title || product[0].name : '',
        metaTitle,
        keywords: product.length > 0 ? product[0].meta_keyword : '',
        description: metaDesciption,
        bottomNav: false,
        header: 'relative', // available values: "absolute", "relative", false (default)
        pageType: 'product',
        ogContent: {
            description: {
                type: 'meta',
                value: metaDesciption || StripHtmlTags(product[0].description.html),
            },
            'og:image': product[0].small_image.url,
            'og:image:type': 'image/jpeg',
            'og:description': product[0].meta_description || StripHtmlTags(product[0].description.html),
            'og:image:width': features.imageSize.product.width,
            'og:image:height': features.imageSize.product.height,
            'og:image:alt': product[0].name || '',
            'og:type': 'product',
            'product:availability': product[0].stock_status,
            'product:category': product[0].categories && product[0].categories.length > 0 && product[0].categories[0].name,
            'product:condition': 'new',
            'product:price:currency': product[0].price_range.minimum_price.final_price.currency,
            'product:price:amount': product[0].price_range.minimum_price.final_price.value,
            'product:pretax_price:currency': product[0].price_range.minimum_price.final_price.currency,
            'product:pretax_price:amount': product[0].price_range.minimum_price.final_price.value,
        },
        schemaOrg,
    };

    return (
        <Layout pageConfig={pageConfig || config} CustomHeader={CustomHeader ? <CustomHeader /> : <Header />} {...props}>
            <ContentDetail
                product={product}
                loadingRelatedProducts={loadingRelatedProducts}
                dataRelatedProducts={dataRelatedProducts}
                loadingUpsellProducts={loadingUpsellProducts}
                dataUpsellProducts={dataUpsellProducts}
                t={t}
                Content={Content}
                isLogin={isLogin}
                weltpixel_labels={weltpixel_labels}
            />
        </Layout>
    );
};

export default PageDetail;
