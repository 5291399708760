import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreateBorder, CreateMargin, CreatePadding } from '@theme_mixins';
import { PRIMARY } from '@theme_color';
import { FONT_DEFAULT, FONT_10, FONT_REGULAR } from '@theme_typography';

export default makeStyles((theme) => ({
    tabContentWrapper: {
        padding: '15px 0',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            gap: '15px',
        },
        '&:first-child': {
            paddingTop: 0,
        },
        '&:last-child': {
            paddingBottom: 0,
        },
    },
    title: {
        paddingBottom: '14px',
        minWidth: '160px',
    },
    tabsStyle: {
        '& .MuiTab-wrapper': {
            fontWeight: 'bold',
            color: '#1A1818',
        },
    },
    root: {
        width: '100%',
        ...CreateMargin(15, 0, 15, 0),
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    expandContainer: {
        boxShadow: 'none',
        borderRadius: 'none',
        margin: 0,
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column',
        },
    },
    headerExpand: {
        padding: 0,
        height: 40,
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    bodyExpand: {
        ...CreatePadding(0, 0, 20, 0),
        margin: 0,
    },
    headerOpen: {
        borderBottom: 'none !important',
    },
    bodyOpen: {
        ...CreateBorder(0, 0, '1px', 0, PRIMARY),
    },
    icon: {
        fontSize: 16,
        color: PRIMARY,
    },
    descriptionHtml: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        overflowY: 'unset',
        width: '100%',
        '&:not(.technology-attr-content)': {
            maxHeight: '100%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                overflowY: 'unset',
            },
        },
        '&::-webkit-scrollbar': {
            width: 8,
            background: '#96999C',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#96999C',
            borderLeft: '3px solid white',
            borderRight: '3px solid white',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#FF6A00',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            overflowY: 'unset',
        },
        '& img': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
        '& .MuiList-root': {
            display: 'flex',
            gap: '15px',
            flexWrap: 'wrap',
            padding: 0,
            '& .MuiButtonBase-root': {
                padding: 0,
                flexDirection: 'column',
                width: 'auto',
                '& .MuiListItemIcon-root': {
                    maxWidth: '100px',
                    maxHeight: '100px',
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            '& img': {
                objectFit: 'cover',
                borderRadius: '10px',
            },
        },
    },

    listLabel: {
        ...FONT_REGULAR,
    },
    listValue: {
        ...FONT_DEFAULT,
        ...FONT_10,
    },

    listItemText: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        maxWidth: '105px',
        fontSize: '14px',
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
    },

    listItemIcon: {
        width: '100px',
        height: '100px',
        flexShrink: 0,
    },

    [theme.breakpoints.up('md')]: {
        showMoreWrapper: {
            overflow: 'hidden',
            transition: 'all 0.5s ease-in-out',
            position: 'relative',
        },
        showMoreOpen: {
            maxHeight: '500px',
        },
        showMoreClosed: {
            maxHeight: '40px',
        },
        showMoreBtn: {
            width: '100%',
            paddingTop: '15px',
        },
        showMoreBtnOpen: {
            position: 'relative',
        },
        showMoreBtnClosed: {
            position: 'relative',
        },
    },
}));
